.segmented {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 0.7rem;
  background-color: #efefef;
  border-radius: 5px;
  margin-bottom: 4px;
}

.segmented-option {
  flex: 1;
  padding: 0.5rem;
  text-align: center;
  /* Prevent text selection */
  -webkit-user-select: none; /* Safari */
  user-select: none; /* Standard syntax */
  border-radius: 5px;
  min-height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 410;
}

.segmented-option.selectable:hover {
  background-color: #d1d1d1;
}

.segmented-option.selectable:active {
  background-color: #dddddd;
}

.segmented-option.selectable {
  cursor: pointer;
}

.segmented-option.disabled {
  cursor: not-allowed;
  color: #444444;
}

.segmented-option.option_preliminaryInfos.selected {
  background-color: #b6d1ff;
}

.segmented-option.option_benchmark.selected {
  background-color: #ffedc7;
}

.segmented-option.option_quoteSignedOtherFH.selected {
  background-color: #ffdbfb;
}

.segmented-option.option_passedFunerals.selected {
  background-color: #d9c7ff;
}
