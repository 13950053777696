.checkbox {
  position: relative;
  width: 0.9rem;
  height: 0.9rem;
  border: 0.113rem solid #bababa;
  border-radius: 0.2rem;
  cursor: pointer;
  flex-shrink: 0;
  margin-right: 0.3rem;
}

.checkbox-container {
  display: flex;
  align-items: flex-start;
  margin-bottom: 0.5rem;
}

.checkbox.checked {
  background-color: #4dbd15;
}

.checkbox.unchecked {
  background-color: #f56c6c;
}

.checkmark,
.crossmark {
  color: #ffffff;
  font-size: 0.8rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

// CSS crossmark
.checkbox.unchecked::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0.8rem;
  height: 0.084rem;
  background-color: #ffffff;
  transform: translate(-50%, -50%) rotate(50deg);
}

.children-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 0.1rem;
  flex-grow: 1;
}
