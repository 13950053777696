.concerns-header {
  background-color: #e0f2fe;
  padding: 8px 16px;
  border: 2px solid #0ba5ec;
  border-radius: 4px;
  position: relative;
}

.concerns-text {
  color: #000;
  font-family: Helvetica;
  line-height: normal;
  font-style: normal;
}

.concerns-header.no-concerns {
  font-size: 12px;
  font-weight: 400;
}

.concerns-header.concerns-expand {
  border-bottom: 0px solid transparent !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.concerns-header-icon {
  position: absolute;
  top: 11.2px;
}

.concerns-header-icon svg {
  width: 20px;
  height: 20px;
  color: #0ba5ec;
}

.concerns-header-expand-toggle {
  right: 16px;
}

.concerns-header-details-toggle {
  right: 48px;
}

.concerns-details-label {
  font-size: 14px;
  margin-bottom: 5px;
}

.concerns-details-situation {
  margin-top: 10px;
}

.concerns-details-section {
  padding: 5px 0;
}

.concern-details-section-label {
  display: flex;
  flex-direction: column;
  align-items: start;
  font-size: 13px;
  font-weight: 400;
}

.concern-element-name {
  font-size: 13px;
  font-weight: 400;
}

.concerns-header-summary {
  &-separator {
    margin-left: 8px;
    margin-right: 8px;
  }

  &-trigger-ad {
    width: 20.8px;
    height: 20.8px;
    margin-left: 1.6px;
    padding-bottom: 4.8px; // align google logo with other emojis
    vertical-align: middle;
  }

  &-trigger-phone {
    display: inline-block;
    font-size: 14.4px;
    margin-left: 1.6px;
    padding-bottom: 4.8px; // align with other emojis
  }
}

.concerns-elements {
  background-color: #f5fbff;
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
  border: 2px solid #0ba5ec;
  border-top: 0;
}

.concerns-elements-type {
  padding: 8px 12px;
  border-bottom: 2px solid #0ba5ec;
}

.concerns-elements .concerns-elements-type:last-child {
  border-bottom: 0;
}

.concerns-elements-timing {
  display: flex;
  justify-content: start;
  align-items: center;
}

.concerns-elements-timing-padding {
  padding-top: 5px;
}

.concerns-elements-timing-label {
  font-family: Helvetica;
  font-weight: 700;
  padding: 3.2px 0;
  font-size: 12.8px;
}

.concerns-elements-timing-label-arg {
  font-family: Helvetica;
  padding-top: 3.2px;
  padding-bottom: 3.2px;
  font-weight: 700;
  font-size: 12.8px;
}

.concern-element > div {
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-top: 4.8px;
  margin-bottom: 4.8px;
}

.concern-element-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
}

.concern-element-content p {
  margin: 0 !important;
  font-size: 13px;
}

.concern-element-title {
  color: #000000;
  font-weight: bold;
  font-family: Helvetica;
  font-size: 13px;
}

.concern-element-chevron {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.concerns-header-summary-separator {
  margin-right: 2px;
  margin-left: 2px;
}

.concerns-header-summary-trigger-phone,
.concerns-header-summary-trigger-ad {
  width: 15px;
  height: 15px;
}

.concerns-header-summary-trigger-phone-container {
  position: relative;
  border-radius: 5px;
  background-color: #ebe1ff;
  width: 25px;
  height: 25px;
  display: inline-block;
  text-align: center;
  line-height: 25px;
  margin-left: 2px;
}

.concerns-header-summary-trigger-ad-container {
  position: relative;
  border-radius: 5px;
  background-color: #ffead5;
  width: 25px;
  height: 25px;
  display: inline-block;
  text-align: center;
  align-items: center;
  line-height: 25px;
  margin-left: 2px;
}
