.select-list {
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  border-radius: 3px;
  transition: background-color 0.3s ease;
  color: #333;
  font-weight: 500;
}

.select-list:hover {
  background-color: #f0f0f0;
}

.select-list.checked {
  background-color: #e0e0e0;
}

.select-list .chip {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 20px;
  margin-right: 0.05rem;
}

.select-list .chip:before {
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  border: 1px solid gray;
  border-radius: 50%;
}

.select-list.checked .chip:before {
  background-color: #0d99ff;
}

.text-container {
  margin-top: -2.5px;
}
