#app {
  position: relative;
  text-align: left;
  height: 100%;
}

.app-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .app-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.app-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.app-link {
  color: #61dafb;
}

.power-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1rem;
}

.request-source {
  padding: 0.3rem;
  background-color: #bbbbbb;
  color: black;
  width: 5rem;
  height: 0.3rem;
  border-radius: 0.5rem;
  margin-bottom: 0.75rem;
  position: fixed;
  right: 0;
  margin-right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.5rem;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
