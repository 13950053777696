.concern {
  display: flex;
  flex-direction: row;
}

.concern-text {
  color: black;
  font-size: 13px;
  font-weight: 400;
}

.trigger {
  font-size: 10px;
  padding: 5px;
  font-weight: 700;
  border-radius: 4px;
  display: flex;
}

.trigger.ad {
  background-color: #ffead5;
  color: #fd853a;
}

.trigger.phone {
  background-color: #ebe1ff;
  color: #7d58cb;
}

.trigger-icon {
  margin-right: 4px;
}

.concern-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 4px;
}
