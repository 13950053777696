.btn-validate-grey {
  background-color: #b9b9ba;
  color: #ffffff;
  width: 120% !important;
  border-radius: 5px !important;
}

.black-line {
  height: 1px;
  background-color: #000;
  width: 100%;
  margin-top: 0.9375rem;
}
